@font-face {
  font-family: "GoshaSans-Bold";
  font-weight: bold;
  src: url("./fonts/GoshaSans-Bold.otf") format("opentype");
}

@font-face {
  font-family: "GoshaSans-Ultralight";
  font-weight: lighter;
  src: url("./fonts/GoshaSans-Ultralight.otf") format("opentype");
}

@font-face {
  font-family: "StagSans-Light";
  font-weight: lighter;
  src: url("./fonts/StagSans-Light.otf") format("opentype");
}

@font-face {
  font-family: "GoshaSans-Regular";
  font-weight: regular;
  src: url("./fonts/GoshaSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "StagSans-LightItalic";
  font-weight: light-italic;
  src: url("./fonts/StagSans-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: "StagSans-Thin";
  font-weight: thin;
  src: url("./fonts/StagSans-Thin.otf") format("opentype");
}

@font-face {
  font-family: "StagSans-ThinItalic";
  font-weight: thin;
  font-style: italic;
  src: url("./fonts/StagSans-ThinItalic.otf") format("opentype");
}

/* Make Font Copy here */

/*  */

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: "StagSans-Light", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0ede8;
}

h1,
h2,
h3,
h4 {
  font-family: "GoshaSans-Bold", Arial, Helvetica, sans-serif;
  line-height: 1;
}
a {
  /* background: violet;
  color: orange; */
}

a:hover {
  color: #ff3c00;
}
p,
div {
  font-size: 20px;
  font-family: "StagSans-Light", Arial, Helvetica, sans-serif;
  color: #000000;

  /* font-size: 22px; */
  letter-spacing: 0.1px;
  line-height: 1.3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.PageLayout {
  padding: 1.2rem;
  padding-top: 70px;
}
